import React, { useEffect, useMemo, useState, useContext } from "react";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import {
  HotelShopRoomTypePickerVariant,
  HotelShopRoomTypePickerEnum,
  RefundableRoomDetails,
  useDeviceTypes,
} from "halifax";
import queryStringParser from "query-string";

import { PremierCollectionShopConnectorProps } from "./container";
import { DesktopShop } from "./components/DesktopShop";
import { MobileShop } from "./components/MobileShop";
import { CfarDetails } from "../ancillary/components";
import {
  QuoteId,
  CallState,
  RoomProductType,
  RoomProductVariant,
  VIEWED_PC_DETAILS,
  SELECT_PC_ROOM,
  CfarQuoteResultEnum,
  RoomProductWithTransformedIndexes,
  LodgingCollectionEnum,
  VIEWED_LC_DETAILS,
  SELECT_LC_ROOM,
  StayTypesEnum,
} from "redmond";
import { PORTAL_TITLE, SELECT_ROOM_TITLE } from "./textConstants";
import { handlePageRedirectOnSelectRoomProduct } from "./utils/queryStringHelpers";
import { ClientContext } from "../../App";
import {
  AVAILABLE,
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  useExperiments,
} from "../../context/experiments";
import { trackEvent } from "../../api/v0/analytics/trackEvent";
import "./styles.scss";
import { PATH_ANCILLARY_CUSTOMIZE, PATH_BOOK } from "../../utils/paths";

export interface IHotelShopProps
  extends PremierCollectionShopConnectorProps,
    RouteComponentProps {}

export const PremierCollectionShop = (props: IHotelShopProps) => {
  const {
    fetchPremierCollectionShop,
    setSelectedAccount,
    history,
    rewardsAccounts,
    viewedPremierCollectionDetailsProperties,
    trackingProps,
    selectRoomType,
    fetchTravelWalletDetails,
    fetchedPremierCollectionDetails,
    premierCollectionShopRequestId,
    isHotelCfarEnabled,
    fetchCfarQuotes,
    setSelectedCfarId,
    isAddOnOptionAvailable,
    hasSelectedRefundableRoom,
    setHasSelectedRefundableRoom,
    roomInfoProducts,
    roomInfoProductsWithTransformedIndexes,
    fetchTravelWalletCreditHistory,
    selectedLodging,
    setStayType,
    listPaymentMethods,
  } = props;
  const { matchesDesktop, matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;
  const [isReadyToRedirect, setIsReadyToRedirect] = useState<boolean>(false);
  const [openRefundableRoomDetails, setOpenRefundableRoomDetails] =
    useState<boolean>(false);
  const [refundableRoomProduct, setRefundableRoomProduct] =
    useState<RoomProductWithTransformedIndexes>();

  const expState = useExperiments();

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  const LCForPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForPremiumCardHoldersEnabled =
    LCForPremiumCardholderVariant !== CONTROL;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const queryString = useMemo(
    () => queryStringParser.parse(history.location.search),
    [history.location.search]
  );

  const handleReadyToRedirect = () => {
    // note: it needs to wait for `selectRoomType` to be executed before proceeding with the page redirect
    setTimeout(() => {
      setIsReadyToRedirect(true);
    });
  };

  const roomInfoProductsType: HotelShopRoomTypePickerVariant = {
    roomInfoProductsWithTransformedIndexes,
    customComponents: {
      RefundableRoomDetails: (props: {
        roomProduct: RoomProductWithTransformedIndexes;
      }) => (
        <RefundableRoomDetails
          {...props}
          setRefundableRoomProduct={setRefundableRoomProduct}
          setOpenRefundableRoomDetails={setOpenRefundableRoomDetails}
          isMobile={matchesMobile}
        />
      ),
    },
    customStates: {
      // note: refundable room is disabled on agent portal
      isRefundableRoomDisabled: isAgentPortal,
      hasSelectedRefundableRoom,
      setHasSelectedRefundableRoom: (
        hasSelectedRefundableRoom: boolean,
        quoteId?: QuoteId
      ) => {
        setHasSelectedRefundableRoom(hasSelectedRefundableRoom);
        setSelectedCfarId(
          hasSelectedRefundableRoom && !!quoteId ? quoteId : null
        );
      },
    },
    variant: HotelShopRoomTypePickerEnum.WithTransformedIndexes,
  };

  useEffect(() => {
    if (selectedLodging) {
      document.title = selectedLodging.lodging.name;
    }
  }, [selectedLodging]);

  useEffect(() => {
    fetchTravelWalletDetails();
    listPaymentMethods();
    setStayType(StayTypesEnum.Hotels);
    document.title = SELECT_ROOM_TITLE;
    setTimeout(() => window.scrollTo(0, 0), 0);

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    if (fetchedPremierCollectionDetails) {
      trackEvent({
        eventName:
          selectedLodging?.lodgingCollection === LodgingCollectionEnum.Lifestyle
            ? VIEWED_LC_DETAILS
            : VIEWED_PC_DETAILS,
        ...trackingProps,
        ...viewedPremierCollectionDetailsProperties,
      });
    }
  }, [fetchedPremierCollectionDetails]);

  useEffect(() => {
    if (
      [CallState.Success, CallState.Failed].includes(expState.callState) ||
      (expState.experiments.length > 0 &&
        (!isGlobalMobileNavExperiment ||
          history.location.state === undefined ||
          history.location.state === null ||
          ![PATH_BOOK, PATH_ANCILLARY_CUSTOMIZE].includes(
            history.location.state["fromPage"]
          )))
    ) {
      fetchPremierCollectionShop(history, {
        overrideStateByQueryParams: true,
      });
      setTimeout(() => window.scrollTo(0, 0), 0);
    }
  }, [expState.callState, queryString.lodgingId]);

  useEffect(() => {
    if (isHotelCfarEnabled && premierCollectionShopRequestId) {
      fetchCfarQuotes({ opaqueRequest: premierCollectionShopRequestId });
    }
  }, [premierCollectionShopRequestId]);

  useEffect(() => {
    const { selectedAccountIndex } = queryStringParser.parse(
      history.location.search
    );

    if (selectedAccountIndex && rewardsAccounts.length > 0) {
      setSelectedAccount(
        rewardsAccounts[Number(selectedAccountIndex)]?.accountReferenceId
      );
    }
  }, [rewardsAccounts]);

  /*
    note: selectRoomType (the action that updates indexes in redux state) is called within a halifax component as a callback;
    it's executed at the same time as when `onClickContinue` is handled, which means that some states are not yet updated.
  */
  useEffect(() => {
    if (isReadyToRedirect) {
      handlePageRedirectOnSelectRoomProduct({
        history,
        isAddOnOptionAvailable,
      });
      setIsReadyToRedirect(false);
    }
  }, [history, isAddOnOptionAvailable, isReadyToRedirect]);

  useEffect(() => {
    if (isTravelCreditHistoryExperiment) {
      fetchTravelWalletCreditHistory();
    }
  }, [isTravelCreditHistoryExperiment]);

  return (
    <>
      <Box className={"premier-collection-shop-root"}>
        {matchesDesktop && (
          <DesktopShop
            roomInfoProductsType={roomInfoProductsType}
            handleReadyToRedirect={handleReadyToRedirect}
            variant={
              (isLCForNonPremiumCardHoldersEnabled ||
                isLCForPremiumCardHoldersEnabled) &&
              selectedLodging?.lodgingCollection ===
                LodgingCollectionEnum.Lifestyle
                ? "lifestyle-collection"
                : "default"
            }
          />
        )}
        {matchesMobile && (
          <MobileShop
            roomInfoProductsType={roomInfoProductsType}
            handleReadyToRedirect={handleReadyToRedirect}
            variant={
              (isLCForNonPremiumCardHoldersEnabled ||
                isLCForPremiumCardHoldersEnabled) &&
              selectedLodging?.lodgingCollection ===
                LodgingCollectionEnum.Lifestyle
                ? "lifestyle-collection"
                : "default"
            }
          />
        )}
      </Box>
      {!!refundableRoomProduct?.roomProductVariant &&
        refundableRoomProduct.roomProductVariant.variant ===
          RoomProductType.RefundableRoom && (
          <CfarDetails
            openCfarDetails={openRefundableRoomDetails}
            onClose={() => setOpenRefundableRoomDetails(false)}
            onContinue={() => {
              // note: refundable room is disabled on agent portal
              if (isAgentPortal) {
                return;
              }

              const quoteId = getQuoteId(
                refundableRoomProduct.roomProductVariant
              );
              const baseProduct =
                refundableRoomProduct.roomProductVariant?.baseProduct;

              if (!!quoteId) {
                setHasSelectedRefundableRoom(true);
                setSelectedCfarId(quoteId);
              }

              if (!!baseProduct) {
                selectRoomType(baseProduct.roomIndex, baseProduct.productIndex);
                trackEvent({
                  eventName:
                    selectedLodging?.lodgingCollection ===
                    LodgingCollectionEnum.Lifestyle
                      ? SELECT_LC_ROOM
                      : SELECT_PC_ROOM,
                  properties: {
                    room_type:
                      roomInfoProducts[baseProduct.roomIndex]?.roomInfo.name,
                  },
                });
                handleReadyToRedirect();
              }
            }}
            currentHotelCfarQuote={{
              id: refundableRoomProduct.roomProductVariant.quoteId,
              premiumPrices:
                refundableRoomProduct.roomProductVariant.premiumPrices,
              coverageAmount:
                refundableRoomProduct.roomProductVariant.coverageAmount,
              coveragePercentage:
                refundableRoomProduct.roomProductVariant.coveragePercentage,
              CfarQuoteResult: CfarQuoteResultEnum.HotelCfarQuote,
            }}
            currentCancellationPolicy={
              refundableRoomProduct.roomProductVariant.cancellationPolicy
            }
            cardContentProps={{ hideRadioButtons: true }}
            contentOnly={false}
            modalType="refundable-room"
            contentClassName="pc-refundable-room-details-modal"
          />
        )}
    </>
  );
};

const getQuoteId = (roomProductVariant: RoomProductVariant | undefined) =>
  roomProductVariant?.variant === RoomProductType.RefundableRoom
    ? roomProductVariant.quoteId
    : undefined;
